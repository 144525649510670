<template>
  <v-layout>
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 25px"></div>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>
              {{ $route.name }}
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-container fluid class="px-0 pt-5">
      <v-col cols="11" xl="8" lg="10" md="10" sm="11" class="mx-auto px-0 pt-0">
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-text class="pa-0 subtitle-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <v-col cols="12">
                        <span class="title">Neue Aufgabe anlegen</span>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="newTodo.Todo" dense hide-details outlined rows="3"></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense align="center">
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn color="green lighten-2" outlined :disabled="!newTodo.Todo" @click="saveTodo"
                          ><v-icon left>mdi-check</v-icon>To-Do speichern</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider class="my-3"></v-divider>

                <v-row dense class="align-center justify-center">
                  <v-col cols="12">
                    <v-timeline>
                      <!-- erstellte Todos -->
                      <v-timeline-item color="red lighten-2" v-if="created.length > 0">
                        <template v-slot:opposite>
                          <span class="headline">erstellt</span>
                        </template>
                        <v-card class="elevation-2 my-1" v-for="todo in created" :key="todo.Todo_ID">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">{{ todo.Todo }}</v-col>
                            </v-row>
                            <v-divider v-if="todo.Antwort && checkCurrentUserAline" class="my-1"></v-divider>
                            <v-row v-if="todo.Antwort && checkCurrentUserAline" dense align="center">
                              <v-col cols="12">
                                <span class="font-weight-bold">Antwort: </span>
                                <span>{{ todo.Antwort }}</span>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-row dense align="center">
                              <v-col cols="auto">
                                <small>{{
                                  new Date(todo.Datum).toLocaleDateString("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}</small></v-col
                              >
                              <v-spacer></v-spacer>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="nextStep(todo)"><v-icon>mdi-chevron-down</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-timeline-item>
                      <v-timeline-item color="red lighten-2" v-else>
                        <template v-slot:opposite>
                          <span class="headline">erstellt</span>
                        </template>
                        <v-card class="elevation-2 my-1">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">keine Aufgaben erstellt</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                      <!-- in Arbeit -->
                      <v-timeline-item color="orange lighten-2" v-if="inProgress.length > 0">
                        <template v-slot:opposite>
                          <span class="headline">in Arbeit</span>
                        </template>
                        <v-card class="elevation-2 my-1" v-for="todo in inProgress" :key="todo.Todo_ID">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">{{ todo.Todo }}</v-col>
                            </v-row>
                            <v-divider v-if="todo.Antwort && checkCurrentUserAline" class="my-1"></v-divider>
                            <v-row v-if="todo.Antwort && checkCurrentUserAline" dense align="center">
                              <v-col cols="12">
                                <span class="font-weight-bold">Antwort: </span>
                                <span>{{ todo.Antwort }}</span>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-row dense align="center">
                              <v-col cols="auto">
                                <small>{{
                                  new Date(todo.Datum).toLocaleDateString("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}</small></v-col
                              >
                              <v-spacer></v-spacer>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="previousStep(todo)"><v-icon>mdi-chevron-up</v-icon></v-btn>
                              </v-col>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="nextStep(todo)"><v-icon>mdi-chevron-down</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-timeline-item>
                      <v-timeline-item color="orange lighten-2" v-else>
                        <template v-slot:opposite>
                          <span class="headline">in Arbeit</span>
                        </template>
                        <v-card class="elevation-2 my-1">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">keine Aufgaben in Arbeit</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                      <!-- kommt im nächstem Update -->
                      <v-timeline-item color="yellow darken-1" v-if="nextPatch.length > 0">
                        <template v-slot:opposite>
                          <span class="headline">kommt mit nächstem Update</span>
                        </template>
                        <v-card class="elevation-2 my-1" v-for="todo in nextPatch" :key="todo.Todo_ID">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">{{ todo.Todo }}</v-col>
                            </v-row>
                            <v-divider v-if="todo.Antwort && checkCurrentUserAline" class="my-1"></v-divider>
                            <v-row v-if="todo.Antwort && checkCurrentUserAline" dense align="center">
                              <v-col cols="12">
                                <span class="font-weight-bold">Antwort: </span>
                                <span>{{ todo.Antwort }}</span>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-row dense align="center">
                              <v-col cols="auto">
                                <small>{{
                                  new Date(todo.Datum).toLocaleDateString("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}</small></v-col
                              >
                              <v-spacer></v-spacer>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="previousStep(todo)"><v-icon>mdi-chevron-up</v-icon></v-btn>
                              </v-col>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="nextStep(todo)"><v-icon>mdi-chevron-down</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-timeline-item>
                      <v-timeline-item color="yellow darken-2" v-else>
                        <template v-slot:opposite>
                          <span class="headline">kommt mit nächstem Update</span>
                        </template>
                        <v-card class="elevation-2 my-1">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">keine Aufgaben für das nächste Update</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                      <!-- erledigt -->
                      <v-timeline-item color="green lighten-2" v-if="done.length > 0">
                        <template v-slot:opposite>
                          <span class="headline">erledigt</span>
                        </template>
                        <v-card class="elevation-2 my-1" v-for="todo in done" :key="todo.Todo_ID">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">{{ todo.Todo }}</v-col>
                            </v-row>
                            <v-divider v-if="todo.Antwort && checkCurrentUserAline" class="my-1"></v-divider>
                            <v-row v-if="todo.Antwort && checkCurrentUserAline" dense align="center">
                              <v-col cols="12">
                                <span class="font-weight-bold">Antwort: </span>
                                <span>{{ todo.Antwort }}</span>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-row dense align="center">
                              <v-col cols="auto">
                                <small>{{
                                  new Date(todo.Datum).toLocaleDateString("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}</small></v-col
                              >
                              <v-spacer></v-spacer>
                              <v-col cols="auto" v-if="checkCurrentUserAdmin">
                                <v-btn icon @click="previousStep(todo)"><v-icon>mdi-chevron-up</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-timeline-item>
                      <v-timeline-item color="green lighten-2" v-else>
                        <template v-slot:opposite>
                          <span class="headline">erledigt</span>
                        </template>
                        <v-card class="elevation-2 my-1">
                          <v-card-text class="text--primary pa-1">
                            <v-row dense align="center">
                              <v-col cols="12">keine erledigten Aufgaben</v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import MasterdataOverviewAppBar from "../../components/appbars/MasterdataOverviewAppBar.vue";

export default {
  components: {
    MasterdataOverviewAppBar,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      created: [],
      inProgress: [],
      nextPatch: [],
      done: [],
      newTodo: {},
      defaultTodo: {
        Todo: null,
        Todostatus_ID: 1,
      },
    };
  },

  computed: {
    checkCurrentUserAdmin() {
      const user = localStorage.getItem("user");

      if (user === "Admin") {
        return true;
      } else return false;
    },

    checkCurrentUserAline() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/todo/gettodos`);
      const json = await response.json();
      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.newTodo = Object.assign({}, this.defaultTodo);
    },

    async nextStep(todo) {
      const datasetToInsert = {};

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Todo_ID: todo.Todo_ID,
        });
      }

      await fetch("/api/settings/todo/nextstep", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      this.initialize();
    },

    async previousStep(todo) {
      const datasetToInsert = {};

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Todo_ID: todo.Todo_ID,
        });
      }

      await fetch("/api/settings/todo/previousstep", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      this.initialize();
    },

    async saveTodo() {
      const datasetToInsert = {};

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Todo: this.newTodo.Todo,
        });
      }

      await fetch("/api/settings/todo/newtodo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });
      this.initialize();
    },
  },
};
</script>
